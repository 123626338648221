import Splide from '@splidejs/splide';
import fullpage from 'fullpage.js';
import ResponsiveAutoHeight from 'responsive-auto-height';

const setHeader = (index, nextIndex, direction) => {
    const topBar = document.querySelector('.top-bar');

    if (nextIndex.index == 1 && direction == 'down') {
        topBar.classList.add('mini');
    } else if (nextIndex.index == 0 && direction == 'up') {
        topBar.classList.remove('mini');
    }
}

const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

const soStretchPanels = () => {
    [...document.querySelectorAll(".siteorigin-panels-stretch")].forEach((panel) => {
        panel.style.marginLeft = '0px';
        panel.style.marginRight = '0px';
        panel.style.overflowX = 'hidden';

        const windowWidth = document.body.clientWidth;
        const panelWidth = panel.offsetWidth;

        panel.style.marginLeft = `-${parseFloat((windowWidth - panelWidth) / 2)}px`;
        panel.style.marginRight = `-${parseFloat((windowWidth - panelWidth) / 2)}px`;
    });
};

let fullpageInstance = null;

const toggleFullpage = () => {
    const main = document.getElementById('r2');
    if (
        main
        && main.classList.contains('fullpagescroll')
        && !fullpageInstance
        && window.matchMedia('(min-width: 992px)').matches
    ) {
        fullpageInstance = new fullpage('#r2', {
            licenseKey: 'gplv3-license',
            autoScrolling: true,
            scrollHorizontally: true,
            afterRender: function() {
                setTimeout(() => {
                    [...document.querySelectorAll(".fp-watermark")].forEach((watermark) => {
                        watermark.remove();
                    });
                }, 1000);
            },
            onLeave: function(index, nextIndex, direction){
                setHeader(index, nextIndex, direction);
            }
        });
    } else if (
        fullpageInstance
        && !window.matchMedia('(min-width: 992px)').matches
    ) {
        fullpageInstance.destroy('all');
        fullpageInstance = null;
    }
};

document.addEventListener("DOMContentLoaded", () => {
    [...document.querySelectorAll('.reference-carousel')].forEach((referenceCarousel) => {
        new Splide(referenceCarousel, {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            focus: 'center',
            pagination: true,
            breakpoints: {
                787: {
                    perPage: 1,
                },
            }
        }).mount();
    });

    [...document.querySelectorAll('.case-study-terms-carousel')].forEach((caseStudyTermsCarousel) => {
        new Splide(caseStudyTermsCarousel, {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            focus: 'center',
            gap: '30px',
            width: '90%',
            pagination: false,
            breakpoints: {
                787: {
                    perPage: 1,
                },
            }
        }).mount();
    });

    [
        '.home .so-widget-zncr-slider',
        '.home .so-widget-zncr-logos-carousel',
        '.home .so-widget-zncr-reference-carousel',
        '.home .so-widget-zncr-case-study-categories',
    ].forEach((selector) => {
        const section = document.querySelector(selector);
        if (section) {
            section.classList.add('section');
            section.classList.add('section-header');
        }
    });

    document.querySelector(".primary-menu-opener").addEventListener("click", () => {
        document.querySelector("nav").classList.add("active");
    });

    document.querySelector(".primary-menu-closer").addEventListener("click", () => {
        document.querySelector("nav").classList.remove("active");
    });

    soStretchPanels();
    toggleFullpage();
});

window.addEventListener('resize', debounce(() => {
    soStretchPanels();
    toggleFullpage();
}));

window.addEventListener("load", () => {
    if (document.querySelector(".js-mh-case-study")) {
        new ResponsiveAutoHeight('.js-mh-case-study');
    }

    if (document.querySelector(".js-mh-media-post")) {
        new ResponsiveAutoHeight('.js-mh-media-post');
    }

    [...document.querySelectorAll('iframe')].forEach((iframme) => {
        const div = document.createElement('div');
        div.classList.add('iframe-wrapper');
        div.appendChild(iframme.cloneNode(true));
        iframme.parentNode.insertBefore(div, iframme);
        iframme.remove();
    });
});